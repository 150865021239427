import { useNavigate } from "react-router-dom";
import EmailForm from "./elements/email-form";

export default function Contact() {
    const navigate = useNavigate();

    return <main id="contact">
        <button className="back" onClick={() => navigate('/')}>{ '< Назад' }</button>
        <div className="text">
            <p>Телефон:
                <span onClick={event => {
                    event.preventDefault();
                    window.location.replace('tel:+79185815310');
                }}> +7 (918) 581-53-10 </span>
                |
                <span onClick={() => {
                    window.open('https://wa.me/79185815310');
                }}> WhatsApp </span>
                |
                <span onClick={() => {
                    window.open('https://t.me/+79185815310');
                }}> Telegram </span></p>
            <p>Электронная почта: <span onClick={event => {
                event.preventDefault();
                window.location.replace('mailto:nat15@mail.ru');
            }}>nat15@mail.ru</span></p>
            <p><span onClick={() => {
                window.open('https://t.me/borisovajurist')
            }}>Telegram-канал с последними новостями в сфере юриспруденции</span></p>
        </div>
        <div className="map">
            <h3>Нас легко найти!</h3>
            {/* <iframe title="Яндекс-Карта" src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae66f29a60233b803dbc4d94001aa91e39b9a5a06b8f31eb5165287f41dcf2b2e&amp;source=constructor" width="100%" height="300px" frameBorder="0"/> */}
            <iframe title="Яндекс-Карта" src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae66f29a60233b803dbc4d94001aa91e39b9a5a06b8f31eb5165287f41dcf2b2e&amp;source=constructor" width="100%" height="300px" frameBorder="0"/>
        </div>
        <EmailForm/>
    </main>
}